@media (max-width: 991.98px) {

  .top-heading {
    h1 {
      font-size: 40px !important;
      line-height: 44px !important;
    }
  }

  .header.header-transparent {
    //background: $black;
    border-bottom: none;
  }

  .header.header-transparent {
    .inner-header {
      .inner-brand {
        background-image: url(../../assets/images/logo_white.svg);
      }
    }
  }

  .header .extra-nav>ul>li>a {
    color: $white;
  }


  .header {
    &.header-small {

      border-bottom: 1px solid rgba(255, 255, 255, .1);

      .inner-nav>ul>li>a {
        color: $white;
      }

      .inner-header {
        .inner-brand {
          background-image: url(../../assets/images/logo_color.svg);
        }
      }
    }
  }

  .inner-navigation.collapse,
  .top-bar-phone {
    display: none;
  }

  .inner-navigation.collapse.show {
    display: block;

  }

  .inner-navigation.show {
    overflow-y: auto;
    left: 40%;
    opacity: 1;
  }

  .inner-navigation {
    background: rgba(0, 0, 0, 0.95);
    text-align: left !important;
    max-height: 340px;
    height: auto;
    top: 175%;
    right: 0;
    left: 60%;
    opacity: 0;
    transition: left .5s ease, opacity .5s ease, height 0s;
    overflow: hidden;
    text-overflow: clip;
  }

  .inner-nav {
    overflow: hidden;
    display: block;
    padding: 40px 0;
    text-overflow: clip;
    ul {
      height: auto;
      text-overflow: clip;
      li {
        float: none;
        text-overflow: clip;
        margin-right: 30px;
        a {
          padding: 10px 0;
          text-align: center;
          text-overflow: clip;

          span {
            transform: translateY(0);
            top: 0;
            color: #83cdee;
            font-size: 1.2em;
            text-align: right;
            text-overflow: clip;
            white-space: nowrap;
            overflow: hidden;
            display: block;
            width: 100%;
          }

          &:after {
            display: none;
          }
        }
      }

      li.phoneinmenu {
        display: block;
        text-overflow: clip;
      }
    }
  }
}
