*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    min-height: 100%;
    font-family: $main-font;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow-x: hidden;
}

ul,
ol {
    list-style: none;
    padding-left: 0;
}

a {
    text-decoration: none;
    background-color: transparent;
    color: $white;

    &:hover {
        color: $white;
    }
}

section {
    padding: 100px 0;
    background: $white;

    .section-heading {
        h2 {
            letter-spacing: 1px;
            font-size: 4.5rem;
            font-family: $main-font;
            font-weight: $bold;
            margin: 0;
        }

        h4 {
            text-transform: uppercase;
            color: $blue;
            letter-spacing: 1px;
            font-size: 1.3rem;
            font-weight: $regular;
        }
    }

    &.dark {
        .section-heading {
            h2 {
                color: $white;
            }
        }
    }
}

h1 {
    span {
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: white;
        color: transparent;
    }
}
.header {
    position: fixed;
    height: 74px;
    width: 100%;
    transition: height .3s ease-out, background .3s ease-out, box-shadow .3s ease-out;
    transform: translate3d(0, 0, 0);
    z-index: 999;

    .container-fluid {
        position: relative;
        height: 100%;
    }

    &.header-transparent {
        background: transparent;
        //border-bottom: 1px solid rgba(255, 255, 255, .1);
    }

    &.header-small {
        background: $black;
        height: 54px;
        box-shadow: 0 0 60px 0 rgba(255, 255, 255, .07);

        .inner-nav>ul>li>a,
        .extra-nav>ul>li>a {
            color: $white;
        }
    }
}

.inner-header {
    position: relative;
    height: 100%;
    float: left;
    z-index: 1;

    .inner-brand {
        display: inline-block;
        vertical-align: middle;
        height: 39px;
        width: 91px;
        background: url(../assets/images/logo_white.svg) no-repeat center;
        background-size: contain;

        img {
            max-width: 150px;
        }
    }
}

.header-small {
    .inner-header {
        .inner-brand {
            background-image: url(../assets/images/DDH_logo_black.png);
        }
    }
}

.inner-navigation {
    position: absolute;
    height: 100%;
    float: left;
    right: 15px;
    left: 15px;
    text-align: center;
}

.inner-nav,
.extra-nav {

    vertical-align: middle;
    display: inline-block;
    height: 100%;


    ul {

        height: 100%;
        padding: 0;
        margin: 0;

        li {
            position: relative;
            height: 100%;
            float: left;
            padding: 0 .6rem;
            margin: 0;

            a {
                color: #fff;
                position: relative;
                display: block;
                height: 100%;
                width: 100%;
                padding: 0 7px;
                letter-spacing: .05rem;
                //text-transform: uppercase;
                //font-weight: $bold;
                font-size: 1rem;
                transition: all .3s ease-out;

                span {
                    transform: translateY(-50%);
                    position: relative;
                    display: block;
                    top: 50%;
                }

                &:after {
                    position: absolute;
                    background: rgba(255, 255, 255, 0.8);
                    display: block;
                    height: 1px;
                    width: 0;
                    bottom: -1px;
                    left: 50%;
                    content: "";
                    transition: all .3s ease-out;
                }

                &:hover {

                    opacity: .75;

                    &:after {
                        background: #fff;
                        margin-left: 0;
                        width: 100%;
                        left: 0;
                    }
                }

                &.hamburger {
                    &:after {
                        height: 0;
                    }
                }
            }
        }

        li.phoneinmenu {
            display: none;
        }
    }
}

.extra-nav {
    position: relative;
    height: 100%;
    float: right;
    text-align: right;
}

.nav-toggle {
    display: block;
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

.cover-video {
    padding: 0;
    margin: 0;
    position: relative;
    height: available;

    .inner-content {
        padding: 10px 0 100px 0;
        z-index: 3;

        .top-heading {
            padding: 70px 30px 70px 30px;
            font-family: $second-font;
            color: $white;

            h1 {
                font-size: 62px;
                font-weight: 800;
                line-height: 89px;
            }
        }

        .showreel-module {
            height: 100%;
            cursor: pointer;
            position: relative;

            .btn-play {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 60px;
                height: 60px;
                border: 1px solid $white;
                -webkit-border-radius: 50px;
                border-radius: 50px;
                text-align: center;
                line-height: 60px;
                font-size: 27px;
                cursor: pointer;
                display: inline-block;
                -webkit-transition: .15s ease-in-out;
                -o-transition: .15s ease-in-out;
                transition: .15s ease-in-out;
                -webkit-transition-property: color, background, border-color;
                -o-transition-property: color, background, border-color;
                transition-property: color, background, border-color;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-40%, -50%);
                }

                &:hover {
                    background: $white;

                    svg {
                        fill: $black;
                    }
                }

                &:before,
                &:after {
                    content: '';
                    border: 1px solid;
                    border-color: inherit;
                    width: 150%;
                    height: 150%;
                    -webkit-border-radius: 50px;
                    border-radius: 50px;
                    position: absolute;
                    left: -25%;
                    top: -25%;
                    opacity: 1;
                    -webkit-animation: 1s showreel-btn-animation linear infinite;
                    animation: 1s showreel-btn-animation linear infinite;
                }

                &:before {
                    -webkit-animation-delay: .5s;
                    animation-delay: .5s;
                }
            }

            span {
                margin-top: 160px;
                text-transform: uppercase;
                font-size: 1.4rem;
                font-weight: $medium;
            }
        }
    }
} // end cover video

.overlay-background {
    background: #000000;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0.35;
    z-index: -1;
}

.about-us {
  .subtitle {
    font-weight: $regular;
  }
  h4 {
    font-weight: $bold;
    font-size: 2.5em;
  }

  h4:not(.subtitle),
  p {
    padding: 0 20px;
  }

    .gallery {
        display: grid;
        height: 600px;
        width: 100%;
        margin: 60px 0;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 32%);
        grid-column-gap: 2px;
        grid-row-gap: 2px;
    }

    .big,
    .small_top,
    .small_bottom {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .big {
        //grid-area: 1 / 1 / 3 / 2;
        background-image: url(../assets/images/about/1.jpg);
    }

    .small_top {
        //grid-area: 1 / 2 / 2 / 3;
        background-image: url(../assets/images/about/2.jpg);
    }

    .small_bottom {
        //grid-area: 2 / 2 / 3 / 3;
        background-image: url(../assets/images/about/3.jpg);
    }
}

.clients {
    background: $light-gray;
    padding: 30px 0;
}

.prefooter {
  position: relative;
  display: flex;
  z-index: 0;

  .jarallax-img {
    position: absolute;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: $black;
    opacity: .7;
  }

  h2 {
    font-weight: $bold;
    font-size: 3rem !important;
    color: $white;
  }

  & > * {
    z-index: 100;
  }
}

.footer {
    background: $black;
    color: $white;
    padding: 90px 0 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;

    .widget {
        margin-bottom: 60px;

        .widget-title {
            margin-bottom: 24px;

            h6 {
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: 700;
                margin: 0;
            }
        }

        .widget-content {
            p {
                font-weight: $thin;

                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}


.pagination_container {
  //top: 65vh !important;
  top: calc(50% + 50px) !important;
  height: 10px;
}

.pagination_page {
  @media (max-width: 720px) {
    height: 15px;
    width: 15px;
    margin-left: 10px;
  }

  border: solid 1px white;
  background: transparent;

  &.is-active {
    background: white;
  }
}

@media (max-width: 420px) {
  .pagination_container {
    top: 75vh !important;
    //top: calc(50% + 50px) !important;
  }
}

@media (min-width: 421px) and (max-width: 720px) {
  .pagination_container {
    top: calc(40%) !important;
  }
}

@media (min-width: 721px) and (max-width: 1399.99px) {
  .pagination_container {
    top: calc(50% + 50px) !important;
  }
}

@media (min-width: 1400px) {
  .pagination_container {
    top: calc(60% + 50px) !important;
  }
}


.grecaptcha-badge {
  visibility: hidden;
}
