.btn {
    border-radius: 0;
    font-family: $main-font;
    padding: 10px 40px;
}

.btn-secondary {
    background-color: $blue;
    border-color: $blue;
}

.portfolio-btn {
    display: inline-block;
    //border: 2px solid $blue;
    height: 60px;
    width: 60px;
    margin: 0 5px !important;
    border-radius: 50%;
    position: relative;
    background: none;
    transform: none;
    opacity: 1;
    left: 0;
    right: 0;

    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        stroke: $white;
    };

}
