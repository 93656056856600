@media (min-width: 992px) {
  .nav-toggle {
    display: none !important;
  }

  .inner-navigation.collapse {
    display: block;
  }

  .about-us {
    .gallery {
      grid-template-columns: 64% 35%;
      grid-template-rows: repeat(2, 48%);
    }

    .big {
      grid-area: 1 / 1 / 3 / 2;
    }

    .small_top {
      grid-area: 1 / 2 / 2 / 3;
    }

    .small_bottom {
      grid-area: 2 / 2 / 3 / 3;
    }
  }
}
