@-webkit-keyframes showreel-btn-animation {
    0% {
        -webkit-transform: scale(0.68);
        transform: scale(0.68);
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes showreel-btn-animation {
    0% {
        -webkit-transform: scale(0.68);
        transform: scale(0.68);
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }
}