// Variables

$black: #000000;
$dark: #222222;
$white: #ffffff;
$gray: #747474;
$light-gray: #F3F3F3;
$blue: #1F96D4;
$lime: #e0de18;

@font-face {
  font-family: 'trasandina';
  font-weight: normal;
  src: url("assets/fonts/TrasandinaRegular.ttf");
}

@font-face {
  font-family: 'trasandina';
  font-weight: 500;
  src: url("assets/fonts/TrasandinaBold.ttf");
}

@font-face {
  font-family: 'trasandina';
  font-weight: 700;
  src: url("assets/fonts/TrasandinaBlack.ttf");
}

@font-face {
  font-family: 'trasandina';
  font-weight: 300;
  src: url("assets/fonts/TrasandinaLight.ttf");
}

$main-font: 'trasandina', sans-serif;
$second-font: 'Roboto Condensed', sans-serif;

$thin: 300;
$regular: 500;
$medium: 600;
$bold: 700;

// Fonts

@import url('https://use.typekit.net/asm3ogv.css');

// Utilities

@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import 'scss/hambuger-settings';
@import '../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';
@import 'scss/buttons';
@import 'scss/animations';
@import 'scss/style';
@import 'scss/breakpoints';
